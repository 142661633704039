<template>
  <VueEcharts :option="chartData" :resizable="true" style="height: 500px" />
</template>

<script setup>
import { ref, computed, defineProps } from 'vue'
import { useStore } from 'vuex'
import { VueEcharts } from 'vue3-echarts'
import { useI18n } from 'vue-i18n'
import { useFormatCurrency } from '@/plugins'
import {
  getLocalizedAbbreviatedMonth,
  getLocalizedAbbreviatedDaysOfMonth
} from '@utils/date'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    default: 'bar'
  },
  window: {
    type: String,
    default: 'daily'
  }
})

const { t } = useI18n()

const formatCurrency = useFormatCurrency()

const periodTranslations = ref({
  current: t('pages.home.period.current'),
  previous: t('pages.home.period.previous')
})

const store = useStore()
const locale = computed(() => store.getters['Config/getLocale'])

const hasData = computed(() =>
  props?.data?.series?.some((item) => item.total > 0)
)

const formattedAxisLabels = computed(() => {
  const axisLabels = props?.data?.axisLabels ?? []
  if (props.window === 'monthly') {
    return axisLabels.map((month) =>
      getLocalizedAbbreviatedMonth(month, locale.value)
    )
  } else if (props.window === 'daily') {
    return axisLabels.map((value) => {
      return getLocalizedAbbreviatedDaysOfMonth(value, locale.value)
    })
  }
  return axisLabels
})

const chartData = computed(() => ({
  animation: false,
  title: {
    show: !hasData.value,
    textStyle: {
      color: '#a7abb1',
      fontSize: '1rem',
      fontWeight: 'normal'
    },
    text: t('pages.home.widgets.no_data_available'),
    left: 'center',
    top: 'center'
  },
  xAxis: {
    show: hasData.value,
    type: 'category',
    data: formattedAxisLabels.value,
    axisLine: { show: false },
    axisTick: { show: false },
    splitLine: { show: false }
  },
  yAxis: {
    show: hasData.value,
    type: 'value',
    nameTextStyle: {
      color: 'red'
    },
    axisLabel: {
      formatter: (value) => {
        const currency = props?.data?.series[0].unit
        return formatCurrency(value, currency, { precision: 0 })
      }
    },
    splitNumber: 4
  },
  grid: {
    left: 0,
    bottom: 0,
    top: 10,
    right: 10,
    containLabel: true
  },
  tooltip: {
    transitionDuration: 0,
    formatter: ({ name, color, data, seriesId, seriesIndex, dataIndex }) => {
      const currency = props?.data?.series[seriesIndex].unit
      return `
      <div class="flex flex-col">
        <div class="text-sm font-semibold">
          <span class="inline-block mr-2 w-2 h-2 rounded-full" style="background-color: ${color};"></span>
          ${name}
        </div>
        <div class="text-sm font-semibold">
          ${t('pages.home.revenue')}: ${formatCurrency(data, currency)}
        </div>
        <div class="text-sm">
          ${t('pages.home.period.label')}: ${periodTranslations.value[seriesId]}
        </div>
      </div>`
    }
  },
  color: ['#279ff6', '#A9D9FB'],
  series: (props?.data?.series ?? [])
    .map(({ data, period }) => ({
      id: period,
      data,
      type: props.type,
      symbolSize: 10,
      itemStyle: { borderRadius: 2 },
      barMaxWidth: 24,
      barMinHeight: 6,
      barGap: 0
    }))
    .slice(0, hasData.value ? 2 : 0)
}))
</script>
