<template>
  <div>
    <div class="grid grid-cols-2 gap-6 mb-6">
      <section class="grid grid-cols-2 gap-6">
        <Widget
          :title="t('pages.home.average_value')"
          icon="card"
          :loading="loading"
        >
          <WidgetText
            :value="data.revenueAverage.value"
            :diff="data.revenueAverage.diff"
            :unit="data.revenueAverage.unit"
          />
        </Widget>
        <Widget
          :title="t('pages.home.return_rate')"
          icon="return"
          :loading="loading"
        >
          <WidgetText
            :value="data.returnRate.value"
            :diff="data.returnRate.diff"
            :unit="data.returnRate.unit"
          />
        </Widget>
        <Widget
          :title="t('pages.home.open_purchase_orders')"
          icon="shopping-cart-dark"
          :loading="loading"
        >
          <WidgetText
            :value="data.openPurchaseOrdersCount.value"
            :diff="data.openPurchaseOrdersCount.diff"
            :unit="data.openPurchaseOrdersCount.unit"
            :redirect="data.openPurchaseOrdersCount.redirect"
          />
        </Widget>
        <Widget
          :title="t('pages.home.purchase_order_value')"
          icon="return"
          :loading="loading"
        >
          <WidgetText
            :value="data.openPurchaseOrdersExpense.value"
            :diff="data.openPurchaseOrdersExpense.diff"
            :unit="data.openPurchaseOrdersExpense.unit"
            :redirect="data.openPurchaseOrdersExpense.redirect"
          />
        </Widget>
      </section>
      <Widget
        :title="
          t('pages.home.top_payment_methods', { currency: currencySymbol })
        "
        title-style="bold"
        :loading="loading"
      >
        <WidgetTopPaymentMethodsChart :data="data.topPaymentMethods.chart" />
      </Widget>
    </div>
    <Widget
      header-size="large"
      :title="t('pages.home.net_revenue')"
      title-style="bold"
      :total="props?.data?.revenueTotal?.total?.value ?? ''"
      :diff="props?.data?.revenueTotal?.total?.diff ?? ''"
      :loading="loading"
      show-tooltip
      class="mb-6"
    >
      <template #title>
        <el-icon
          class="mx-4 text-lg cursor-pointer"
          @click="revenueTotalChartType = CHART_TYPES.BAR"
        >
          <svgicon
            :src="require(`@/assets/icons/th-chart-bar.svg`)"
            class="svg-icon"
            :class="
              revenueTotalChartType === CHART_TYPES.BAR
                ? 'text-th-primary'
                : 'text-th-secondary-gray'
            "
          />
        </el-icon>
        <el-icon
          class="text-lg cursor-pointer"
          @click="revenueTotalChartType = CHART_TYPES.LINE"
        >
          <svgicon
            :src="require(`@/assets/icons/th-chart-line.svg`)"
            class="svg-icon"
            :class="
              revenueTotalChartType === CHART_TYPES.LINE
                ? 'text-th-primary'
                : 'text-th-secondary-gray'
            "
          />
        </el-icon>
      </template>
      <WidgetRevenueChart
        :data="data.revenueTotal.chart"
        :type="revenueTotalChartType"
        :window="data.revenueTotal.window"
      />
    </Widget>
    <div class="grid grid-cols-3 gap-6">
      <Widget
        class="col-span-2"
        header-size="medium"
        :loading="loading || topProductsLoading"
      >
        <th-table
          :data="data.revenueTopProducts"
          :columns="topProductsColumns"
          :no-data-text="$t('pages.home.widgets.no_data_available')"
          alternate-heading
          disable-pagination
        >
          <template #thTableHeader:revenue="{ thTableCell }">
            <sortable-header
              v-model="topProductsRevenueSortOrder"
              @click="$ampli.eventWithBaseProps('homeBestWorstProduClick')"
            >
              {{ thTableCell }}
            </sortable-header>
          </template>
          <template #thTableHeader:units="{ thTableColumn, thTableCell }">
            <sortable-header
              v-if="thTableColumn.sortable"
              v-model="topProductsUnitsSortOrder"
              @click="$ampli.eventWithBaseProps('homeBestWorstProduClick')"
            >
              {{ thTableCell }}
            </sortable-header>
            <template v-else>
              {{ thTableCell }}
            </template>
          </template>
        </th-table>
      </Widget>
      <Widget
        header-size="medium"
        :title="
          $t('pages.home.sales_by_category', { currency: currencySymbol })
        "
        title-style="bold"
        :total="props?.data?.topProductGroups?.total?.value ?? ''"
        :diff="props?.data?.topProductGroups?.total?.diff ?? ''"
        :loading="loading"
        :hide-total-title="true"
      >
        <WidgetTopProductGroupsChart
          :data="data.topProductGroups.chart"
          :redirect="data.topProductGroups.redirect"
        />
      </Widget>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useFormatCurrency } from '@/plugins'
import ThTable from '@/components/table'
import SortableHeader from '@/components/sortable-header/sortable-header.vue'
import Widget from './widget'
import WidgetRevenueChart from './widget-revenue-chart'
import WidgetTopProductGroupsChart from './widget-top-product-groups-chart'
import WidgetTopPaymentMethodsChart from './widget-top-payment-methods-chart'
import WidgetText from './widget-text'

const CHART_TYPES = {
  BAR: 'bar',
  LINE: 'line'
}

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  currency: {
    type: String,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  topProductsLoading: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()
const formatCurrency = useFormatCurrency()

const emit = defineEmits(['changeSort'])

const revenueTotalChartType = ref(CHART_TYPES.BAR)

const topProductsRevenueSortOrder = ref('desc')
const topProductsUnitsSortOrder = ref(null)

const topProductsTableHeading = computed(() => {
  if (
    topProductsRevenueSortOrder.value &&
    topProductsRevenueSortOrder.value === 'asc'
  ) {
    return t('pages.home.least_selling_by_rev')
  }
  if (
    topProductsRevenueSortOrder.value &&
    topProductsRevenueSortOrder.value === 'desc'
  ) {
    return t('pages.home.most_selling_by_rev')
  }
  if (
    topProductsUnitsSortOrder.value &&
    topProductsUnitsSortOrder.value === 'asc'
  ) {
    return t('pages.home.least_selling_by_units')
  }
  if (
    topProductsUnitsSortOrder.value &&
    topProductsUnitsSortOrder.value === 'desc'
  ) {
    return t('pages.home.most_selling_by_units')
  }
  return t('pages.home.least_selling_by_rev')
})

const currencySymbol = computed(() => {
  return formatCurrency(0, props.currency, { precision: 0 })
    .replace('0', '')
    .trim()
})

watch(topProductsRevenueSortOrder, () => {
  if (topProductsRevenueSortOrder.value) {
    emit('changeSort', {
      by: 'revenue',
      order: topProductsRevenueSortOrder.value?.toUpperCase()
    })
    topProductsUnitsSortOrder.value = ''
  }
})

watch(topProductsUnitsSortOrder, () => {
  if (topProductsUnitsSortOrder.value) {
    emit('changeSort', {
      by: 'totalCount',
      order: topProductsUnitsSortOrder.value?.toUpperCase()
    })
    topProductsRevenueSortOrder.value = ''
  }
})

const topProductsColumns = computed(() => [
  {
    label: topProductsTableHeading.value,
    key: 'product',
    visible: true
  },
  {
    label: t('pages.home.average_sell_price'),
    key: 'price',
    visible: true
  },
  {
    label: t('pages.home.revenue'),
    key: 'revenue',
    visible: true,
    sortable: true
  },
  {
    label: t('pages.home.units_sold'),
    key: 'units',
    visible: true,
    sortable: true
  }
])
</script>
