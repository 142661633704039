import compose from 'just-compose'
import typeOf from 'just-typeof'
import { getRangeFor, getStartOfDay, getEndOfDay } from '@utils/date'

export const shortcutDatesMap = {
  today: 'goToToday',
  yesterday: 'goToYesterday',
  thisWeek: 'goToThisWeek',
  lastWeek: 'goToLastWeek',
  thisMonth: 'goToThisMonth',
  lastMonth: 'goToLastMonth',
  thisYear: 'goToThisYear',
  lastYear: 'goToLastYear'
}

export const isValidDate = (date) => {
  if (typeOf(date) === 'date') return true
  const dateObj = new Date(date)
  return !Number.isNaN(dateObj.getTime())
}

export const calendarSettings = {
  datePickerShowDayPopover: false,
  formats: {
    title: 'MMMM YYYY',
    weekdays: 'WW',
    navMonths: 'MMM',
    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    dayPopover: 'L',
    data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD']
  }
}

export const getDate = (date) => {
  if (typeOf(date) === 'date') return date
  if (typeOf(date) === 'string') return new Date(date)
  return null
}

export function normalizeDateTimeFormats(date) {
  /*
      v-calendar uses upper-case Y and D for years/days, but we (i.e. date-fns) use lower-case y and d.
      For am/pm, AM/PM formats are also mapped differently per examples ⬇️
      https://vcalendar.io/format-parse-dates.html#parsing-dates
    */
  const replaceYearFormat = (format) => format.replace(/y/g, 'Y')
  const replaceDayFormat = (format) => format.replace(/d/g, 'D')
  const replaceAMPMBigFormat = (format) => format.replace(/\saa$/g, ' A') // 'HH:mm aa' --> 'HH:mm A'  -> '07:55 PM'
  const replaceAMPMSmallFormat = (format) => format.replace(/\saaaa$/g, ' a') // 'HH:mm aaaa' --> 'HH:mm a'  -> '07:55 pm'
  return compose(
    replaceYearFormat,
    replaceDayFormat,
    replaceAMPMBigFormat,
    replaceAMPMSmallFormat
  )(date)
}

/**
 * @typedef DateRange
 * @type {object}
 * @property {Date} start - The start date
 * @property {Date} end - The end date
 *
 * @param {Object} options
 * @param {DateRange} options.value The requested date range
 * @param {Date | null} [options.minDate =  null] The minimum date to cap the range at
 * @param {Date | null} [options.maxDate = null] The maximum date to cap the range at
 * @param {boolean} [options.dateTimeMode = false] When false, includes the whole day of each date
 * @returns {DateRange}
 */
export function getRange({
  value,
  minDate = null,
  maxDate = null,
  dateTimeMode = false
}) {
  let startValue
  let endValue

  //make sure start is before end date
  if (value.start > value.end) {
    startValue = value.end
    endValue = value.start
  } else {
    startValue = value.start
    endValue = value.end
  }

  //if range mode (date only), set start date to beginning of day and end date to end of day
  if (!dateTimeMode) {
    startValue.setHours(0, 0, 0, 0)
    endValue.setHours(23, 59, 59, 999)
  }

  //start date is not before min date
  if (minDate && startValue < minDate) {
    startValue = minDate
  }
  //end date is not after max date
  if (maxDate && endValue > maxDate) {
    endValue = maxDate
  }

  return {
    start: startValue,
    end: endValue
  }
}

//Retund a list of shortcut buttons for the specific range provided
export function getShortcutButtons(vm = { $t: (f) => f }, range = {}) {
  const minDate = range.minDate
    ? new Date(range.minDate).setHours(0, 0, 0, 0)
    : null
  const maxDate = range.maxDate
    ? new Date(range.maxDate).setHours(23, 59, 59, 999)
    : null

  return [
    {
      type: 'today',
      label: vm.$t('components.date_picker.title.today'),
      range: getRangeFor.today()
    },
    {
      type: 'yesterday',
      label: vm.$t('components.date_picker.title.yesterday'),
      range: getRangeFor.yesterday()
    },
    {
      type: 'thisWeek',
      label: vm.$t('components.date_picker.title.this_week'),
      range: getRangeFor.thisWeek()
    },
    {
      type: 'lastWeek',
      label: vm.$t('components.date_picker.title.last_week'),
      range: getRangeFor.lastWeek()
    },
    {
      type: 'thisMonth',
      label: vm.$t('components.date_picker.title.this_month'),
      range: getRangeFor.thisMonth()
    },
    {
      type: 'lastMonth',
      label: vm.$t('components.date_picker.title.last_month'),
      range: getRangeFor.lastMonth()
    },
    {
      type: 'thisYear',
      label: vm.$t('components.date_picker.title.this_year'),
      range: getRangeFor.thisYear()
    },
    {
      type: 'lastYear',
      label: vm.$t('components.date_picker.title.last_year'),
      range: getRangeFor.lastYear()
    }
  ].filter((button) => {
    const { start, end } = button.range
    return (!minDate || start >= minDate) && (!maxDate || end <= maxDate)
  })
}
