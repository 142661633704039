<template>
  <th-page-wrapper background-color="#fafafa">
    <div v-if="!isStaff" class="w-full min-w-full p-6">
      <div class="flex items-center justify-between mb-6">
        <date-range-picker
          placement="bottom-start"
          :locale="locale"
          :model-value="currentDates"
          :date-format="dateFormat"
          :auto-close="false"
          :auto-apply-on-reset="false"
          end-date-time="alwaysEndOfDay"
          no-future-dates
          @update:modelValue="updateDates"
        />
        <p
          v-if="comparisonDates.start && comparisonDates.end"
          class="ml-4 my-0 text-sm"
        >
          {{ $t('pages.home.comparison_text') }} ({{
            formatDateRange(comparisonDates)
          }})
        </p>
        <th-currency-select
          v-show="currencies.length > 1"
          v-model="selectedCurrency"
          class="w-20 ml-auto mr-4"
          :allowed-currencies="currencies"
        />
        <router-link
          :to="{ name: 'home' }"
          :class="`${currencies.length > 1 ? '' : 'ml-auto'}`"
        >
          <el-button
            plain
            @click="$ampli.eventWithBaseProps('homeOldDashboardClick')"
          >
            {{ $t('common.forms.labels.old_flow') }}
          </el-button>
        </router-link>
      </div>
      <Widgets
        :data="widgetsData"
        :loading="isLoading"
        :top-products-loading="topProductsLoading"
        :currency="selectedCurrency"
        @change-sort="handleChangeSort"
      />
    </div>
    <div
      v-else
      class="w-full h-full min-w-full flex flex-col justify-evenly items-center"
    >
      <img :src="staffHome" alt="staff-home-welcome" />
      <div class="font-bold text-lg leading-4 text-th-primary">
        {{ $t('pages.home.staff.welcome.title') }}
      </div>
      <div class="staff-home-content text-base">
        {{ $t('pages.home.staff.welcome.content') }}
      </div>
    </div>
  </th-page-wrapper>
</template>

<script setup>
import { ref, computed, onMounted, watch, inject } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { subDays, set } from 'date-fns'
import { getDateFormat, formatDateRange, formatDateToISO } from '@/utils/date'
import th from '@tillhub/javascript-sdk'
import { useFormatCurrency } from '@/plugins'
import DateRangePicker from '@/components/date-picker/range'
import Widgets from './components/widgets'
import { formatRouterQuery } from '@/utils/format-router-query'
import staffHome from '@/assets/images/staff-home.svg'

const NOW = new Date()

const PERIODS = {
  CURRENT: 'current',
  PREVIOUS: 'previous'
}

const DEFAULT_DATES = {
  start: set(subDays(NOW, 6), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  }).toISOString(),
  end: set(NOW, {
    hours: 23,
    minutes: 59,
    seconds: 59,
    milliseconds: 999
  }).toISOString()
}

const logException = inject('logException')

const formatCurrency = useFormatCurrency()

const i18n = useI18n()
const store = useStore()

const dateFormat = ref(getDateFormat())

const currentDates = ref({
  end: store.getters['Config/getDashboardDates'].end || DEFAULT_DATES.end,
  start: store.getters['Config/getDashboardDates'].start || DEFAULT_DATES.start,
  showDateText: true
})

const comparisonDates = ref({
  start: null,
  end: null,
  window: ''
})

const selectedCurrency = ref(store.getters['Config/getCurrentDefaultCurrency'])

const locale = ref(i18n.locale)

const currencies = computed(
  () => store.getters['Config/getAvailableCurrencies']
)

const location = computed(() => store.getters['Config/getCurrentLocation'])

const isStaff = computed(() => store.getters['Auth/isStaff'])

const isLoading = ref(false)
const topProductsLoading = ref(false)

const purchaseOrders = ref({
  count: [],
  expense: []
})

const revenue = ref({
  average: [],
  total: [],
  topProducts: []
})

const products = ref({
  returnRate: []
})

const topProductGroups = ref({
  series: [],
  axisLabels: [],
  productGroupsNumbers: []
})

const topPaymentMethods = ref({
  series: [],
  axisLabels: []
})

const widgetsData = computed(() => ({
  openPurchaseOrdersCount: {
    value: formatValue(
      purchaseOrders.value.count?.find((i) => i.period === PERIODS.CURRENT)
        ?.total
    ),
    diff: getDiff(
      purchaseOrders.value.count?.find((i) => i.period === PERIODS.CURRENT)
        ?.total,
      purchaseOrders.value.count?.find((i) => i.period === PERIODS.PREVIOUS)
        ?.total
    ),
    unit: null,
    redirect: {
      name: 'purchase-orders-list'
    }
  },
  openPurchaseOrdersExpense: {
    value: formatValue(
      purchaseOrders.value.expense?.find((i) => i.period === PERIODS.CURRENT)
        ?.total,
      purchaseOrders.value.expense?.find((i) => i.period === PERIODS.CURRENT)
        ?.unit
    ),
    diff: getDiff(
      purchaseOrders.value.expense?.find((i) => i.period === PERIODS.CURRENT)
        ?.total,
      purchaseOrders.value.expense?.find((i) => i.period === PERIODS.PREVIOUS)
        ?.total
    ),
    unit: purchaseOrders.value.expense?.find(
      (i) => i.period === PERIODS.CURRENT
    )?.unit,
    redirect: {
      name: 'purchase-orders-list'
    }
  },
  revenueAverage: {
    value: formatValue(
      revenue.value.average?.find((i) => i.period === PERIODS.CURRENT)?.total,
      revenue.value.average?.find((i) => i.period === PERIODS.CURRENT)?.unit
    ),
    diff: getDiff(
      revenue.value.average?.find((i) => i.period === PERIODS.CURRENT)?.total,
      revenue.value.average?.find((i) => i.period === PERIODS.PREVIOUS)?.total,
      true
    ),
    unit: revenue.value.average?.find((i) => i.period === PERIODS.CURRENT)?.unit
  },
  revenueTotal: {
    chart: {
      axisLabels: revenue.value.total?.axisLabels,
      series: revenue.value.total?.series?.filter(
        (item) => item.unit === selectedCurrency.value
      )
    },
    total: {
      value: formatValue(
        revenue.value.total?.series?.find((i) => i.period === PERIODS.CURRENT)
          ?.total,
        revenue.value.total?.series?.find((i) => i.period === PERIODS.CURRENT)
          ?.unit
      ),
      diff: getDiff(
        revenue.value.total?.series?.find((i) => i.period === PERIODS.CURRENT)
          ?.total,
        revenue.value.total?.series?.find((i) => i.period === PERIODS.PREVIOUS)
          ?.total
      ),
      unit: revenue.value.total?.series?.find(
        (i) => i.period === PERIODS.CURRENT
      )?.unit
    },
    window: revenue.value.total?.window
  },
  revenueTopProducts: revenue.value.topProducts,
  returnRate: {
    value: formatValue(
      products.value.returnRate?.find((i) => i.period === PERIODS.CURRENT)
        ?.total,
      products.value.returnRate?.find((i) => i.period === PERIODS.CURRENT)?.unit
    ),
    diff: getDiff(
      products.value.returnRate?.find((i) => i.period === PERIODS.CURRENT)
        ?.total,
      products.value.returnRate?.find((i) => i.period === PERIODS.PREVIOUS)
        ?.total
    ),
    unit: products.value.returnRate?.find((i) => i.period === PERIODS.CURRENT)
      ?.unit
  },
  topProductGroups: {
    chart: {
      axisLabels: topProductGroups.value.axisLabels,
      series: topProductGroups.value.series?.filter(
        (item) => item.unit === selectedCurrency.value
      ),
      extraData: {
        productUnitsSold: {
          current: topProductGroups.value.series?.find(
            (item) =>
              item.unit === 'productUnitsSold' &&
              item.period === PERIODS.CURRENT
          )?.data,
          previous: topProductGroups.value.series?.find(
            (item) =>
              item.unit === 'productUnitsSold' &&
              item.period === PERIODS.PREVIOUS
          )?.data
        }
      }
    },
    total: {
      value: formatValue(
        topProductGroups.value.series?.find(
          (i) =>
            i.period === PERIODS.CURRENT && i.unit === selectedCurrency.value
        )?.total,
        topProductGroups.value.series?.find(
          (i) =>
            i.period === PERIODS.CURRENT && i.unit === selectedCurrency.value
        )?.unit
      ),
      diff: getDiff(
        topProductGroups.value.series?.find(
          (i) =>
            i.period === PERIODS.CURRENT && i.unit === selectedCurrency.value
        )?.total,
        topProductGroups.value.series?.find(
          (i) =>
            i.period === PERIODS.PREVIOUS && i.unit === selectedCurrency.value
        )?.total
      ),
      unit: topProductGroups.value.series?.find(
        (i) => i.period === PERIODS.CURRENT
      )?.unit
    },
    redirect: {
      name: 'reports-statistics-product-groups',
      query: formatRouterQuery({
        filter: {
          date: {
            start: currentDates.value.start,
            end: currentDates.value.end
          },
          product_group_id: [...topProductGroups.value.productGroupsNumbers]
        }
      })
    }
  },
  topPaymentMethods: {
    chart: {
      axisLabels: topPaymentMethods.value.axisLabels,
      series: topPaymentMethods.value.series?.filter(
        (item) => item.unit === selectedCurrency.value
      )
    }
  }
}))

function updateDates(dates = {}) {
  const newDates = {
    start: dates.start ?? DEFAULT_DATES.start,
    end: dates.end ?? DEFAULT_DATES.end
  }
  store.dispatch('Config/setDashboardDates', newDates)
  currentDates.value = {
    ...newDates,
    showDateText: true
  }
}

function getDiff(currentValue, previousValue) {
  if (currentValue && previousValue) {
    return (100 - (previousValue * 100) / currentValue).toFixed(2)
  }
  return ''
}

function formatValue(value = 0, unit = '') {
  if (!unit) {
    return (value || 0).toString()
  }
  if (unit === 'percent') {
    return `${value}%`
  }
  return formatCurrency(value, unit)
}

async function handleChangeSort(sort = {}) {
  try {
    topProductsLoading.value = true
    await getRevenueTopProducts(currentDates.value, sort.by, sort.order)
  } catch (err) {
    logException(err, {
      trackError: false,
      message: i18n.t('common.error.action.read.single', {
        resource: i18n.t('common.resource.product.plural')
      })
    })
  } finally {
    topProductsLoading.value = false
  }
}

async function getOpenPurchaseOrdersCount(date = {}) {
  const response = await th.analyticsV4().getOpenPurchaseOrdersCount({
    currency: selectedCurrency.value,
    compare: true,
    start: date.start,
    end: date.end,
    ...(location.value ? { branch: location.value } : {})
  })
  purchaseOrders.value.count = response?.data?.series ?? []
}

async function getOpenPurchaseOrdersExpense(date = {}) {
  const response = await th.analyticsV4().getOpenPurchaseOrdersExpense({
    currency: selectedCurrency.value,
    compare: true,
    start: date.start,
    end: date.end,
    ...(location.value ? { branch: location.value } : {})
  })
  purchaseOrders.value.expense = response?.data?.series ?? []
}

async function getRevenue(date = {}) {
  const response = await th.analyticsV4().getRevenue({
    currency: selectedCurrency.value,
    compare: true,
    start: date.start,
    end: date.end,
    ...(location.value ? { branch: location.value } : {})
  })
  revenue.value.total.axisLabels = response?.data?.axisLabels ?? []
  revenue.value.total.series = response?.data?.series ?? []
  revenue.value.total.window = response?.data?.window ?? 'daily'
  comparisonDates.value = response?.data?.periods?.previous ?? {}
}

async function getRevenueAverage(date = {}) {
  const response = await th.analyticsV4().getRevenueAverage({
    currency: selectedCurrency.value,
    compare: true,
    start: date.start,
    end: date.end,
    ...(location.value ? { branch: location.value } : {})
  })
  revenue.value.average = response?.data?.series ?? []
}

async function getRevenueTopProducts(
  date = {},
  sortBy = 'revenue',
  sortOrder = 'DESC'
) {
  const response = await th.analyticsV4().getRevenueTopProducts({
    currency: selectedCurrency.value,
    start: date.start,
    end: date.end,
    orderBy: sortBy,
    orderDirection: sortOrder,
    ...(location.value ? { branch: location.value } : {})
  })
  const productIds = response?.data?.axisLabels ?? []
  const topProducts = []

  for (let index = 0; index < productIds.length; index++) {
    try {
      const { data } = await th.products().get(productIds[index])

      const revenue = response?.data?.series.find(
        (i) => i.period === PERIODS.CURRENT && i.unit === selectedCurrency.value
      )?.data?.[index]
      const formattedRevenue = formatCurrency(revenue, selectedCurrency.value)

      const units = response?.data?.series.find(
        (i) => i.period === PERIODS.CURRENT && i.unit === 'productUnitsSold'
      )?.data?.[index]

      const price = formatCurrency(
        (revenue > 0 && units > 0 ? revenue / units : 0).toFixed(2),
        selectedCurrency.value
      )

      topProducts.push({
        product: data?.name,
        price,
        revenue: formattedRevenue,
        units
      })
    } catch (err) {
      logException(err, {
        trackError: false,
        message: i18n.t('common.error.action.read.single', {
          resource: i18n.t('common.resource.product.singular')
        })
      })
    }
  }
  revenue.value.topProducts = topProducts
}

async function getProductsReturnRate(date = {}) {
  const response = await th.analyticsV4().getProductsReturnRate({
    currency: selectedCurrency.value,
    compare: true,
    start: date.start,
    end: date.end,
    ...(location.value ? { branch: location.value } : {})
  })
  products.value.returnRate = response?.data?.series ?? []
}

async function getTopProductGroups(date = {}) {
  const response = await th.analyticsV4().getProductsTopGroups({
    currency: selectedCurrency.value,
    compare: true,
    start: date.start,
    end: date.end,
    ...(location.value ? { branch: location.value } : {})
  })

  let groupsIds = response?.data?.axisLabels ?? []

  const groupsResponse = await th.productGroups().getAll()

  const maxGroupsCount = 5
  const missingGroupsCount = maxGroupsCount - groupsIds.length
  if (missingGroupsCount !== 0) {
    const leftoverGroups = groupsResponse.data.filter(
      (group) => !groupsIds.includes(group.id)
    )

    groupsIds = [
      ...groupsIds,
      ...leftoverGroups
        .filter((item) => item.active && !item.deleted)
        .slice(0, missingGroupsCount)
        .map((group) => group.id)
    ]
  }

  topProductGroups.value.axisLabels = groupsIds.map((id) => {
    const group = groupsResponse.data.find((group) => group.id === id)
    return group?.name ?? i18n.t('pages.home.unknown_group')
  })

  topProductGroups.value.productGroupsNumbers = groupsIds
    .map((id) => {
      const group = groupsResponse.data.find((group) => group.id === id)
      return group?.product_group_id ?? null
    })
    .filter((i) => i)

  topProductGroups.value.series = (response?.data?.series ?? []).map((item) => {
    return {
      ...item,
      data: groupsIds.map((id, index) => item.data[index] ?? 0)
    }
  })
}

async function getTopPaymentMethods(date = {}) {
  const response = await th.analyticsV4().getRevenuePaymentTypes({
    currency: selectedCurrency.value,
    compare: true,
    start: date.start,
    end: date.end,
    ...(location.value ? { branch: location.value } : {})
  })

  let groupsIds = response?.data?.axisLabels ?? []

  const paymentOptionsResponse = await th.paymentOptions().getAll()

  topPaymentMethods.value.axisLabels = groupsIds.map((id) => {
    const group = paymentOptionsResponse.data.find((group) => group.id === id)
    return group?.name ?? i18n.t('pages.home.unknown_method')
  })

  topPaymentMethods.value.series = (response?.data?.series ?? []).map(
    (item) => {
      return {
        ...item,
        data: groupsIds.map((id, index) => item.data[index] ?? 0)
      }
    }
  )
}

async function getData() {
  isLoading.value = true
  try {
    await Promise.all([
      getOpenPurchaseOrdersCount(currentDates.value),
      getOpenPurchaseOrdersExpense(currentDates.value),
      getRevenue(currentDates.value),
      getRevenueAverage(currentDates.value),
      getProductsReturnRate(currentDates.value),
      getRevenueTopProducts(currentDates.value),
      getTopProductGroups(currentDates.value),
      getTopPaymentMethods(currentDates.value)
    ])
  } catch (err) {
    logException(err, {
      trackError: false,
      message: i18n.t('common.error.action.read.single', {
        resource: i18n.t('common.resource.resource.plural')
      })
    })
  }

  isLoading.value = false
}

watch([currentDates, selectedCurrency, location], () => getData())

onMounted(() => getData())
</script>

<style scoped>
.staff-home-content {
  width: 444px;
  height: 54px;
}
</style>
