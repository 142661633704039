<template>
  <article
    class="flex flex-col justify-between bg-white border border-solid h-full overflow-hidden"
    :class="
      isWhiteLabel
        ? 'card-shadow rounded'
        : 'shadow-lg rounded-lg rounded-t-none'
    "
  >
    <header v-if="title">
      <div
        class="flex text items-center border-b justify-between px-4"
        :class="{
          'h-10': headerSize === 'small',
          'h-12': headerSize === 'medium',
          'h-16': headerSize === 'large'
        }"
      >
        <div class="flex">
          <p
            class="m-0 text-sm truncate"
            :class="{
              'font-bold': titleStyle === 'bold'
            }"
          >
            {{ title }}
          </p>
          <th-popover
            v-if="showTooltip"
            :text="$t('pages.home.tooltip_text')"
            class="ml-2"
          />
          <slot name="title" />
        </div>
        <p v-if="total" class="m-0 flex text-sm">
          <span>
            <template v-if="!hideTotalTitle"
              >{{ $t('pages.home.total') }}:
            </template>
            {{ total }}
          </span>
          <WidgetDiff :diff="diff" class="ml-3" />
        </p>

        <el-icon v-if="icon" class="text-2xl">
          <svgicon
            :src="require(`@/assets/icons/th-icon-${icon}.svg`)"
            class="svg-icon"
          />
        </el-icon>
      </div>
    </header>
    <section v-loading="loading" :class="{ 'p-4': title }" class="h-full">
      <slot />
    </section>
  </article>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { isUnifiedCommerce } from '@/constants'
import WidgetDiff from './widget-diff.vue'

defineProps({
  icon: {
    type: String,
    default: ''
  },
  headerSize: {
    type: String,
    default: 'small',
    validator: (value) => {
      return ['small', 'medium', 'large'].includes(value)
    }
  },
  title: {
    type: String,
    default: ''
  },
  titleStyle: {
    type: String,
    default: 'regular',
    validator: (value) => {
      return ['regular', 'bold'].includes(value)
    }
  },
  total: {
    type: String,
    default: ''
  },
  diff: {
    type: String,
    default: ''
  },
  loading: {
    type: Boolean,
    default: false
  },
  hideTotalTitle: {
    type: Boolean,
    default: false
  },
  showTooltip: {
    type: Boolean,
    default: false
  }
})

const isWhiteLabel = computed(() => isUnifiedCommerce())
</script>
