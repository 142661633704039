<template>
  <span
    :class="[
      'inline-flex',
      {
        'text-th-green': diff && isPositive,
        'text-th-red': diff && !isPositive,
        'text-th-secondary-gray': !diff
      }
    ]"
  >
    {{ diff ? `${diff}%` : 'N/A' }}
  </span>
</template>

<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  diff: {
    type: String,
    required: true
  }
})

const isPositive = computed(() => parseInt(props.diff) > 0)
</script>
