<template>
  <VueEcharts
    class="h-full"
    :option="chartData"
    :resizable="true"
    style="min-height: 320px"
    @click="handleClick"
  />
</template>

<script setup>
import { ref, computed, defineProps } from 'vue'
import { VueEcharts } from 'vue3-echarts'
import { useI18n } from 'vue-i18n'
import { useFormatCurrency } from '@/plugins'
import { useRouter } from 'vue-router'
import { getNumberAbbreviationParams } from '@/utils/numbers'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  redirect: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()

const formatCurrency = useFormatCurrency()

const router = useRouter()

function handleClick() {
  if (!props.redirect) throw new Error('No redirect path provided')
  router.push(props.redirect)
}

const periodTranslations = ref({
  current: t('pages.home.period.current'),
  previous: t('pages.home.period.previous')
})

const hasData = computed(() =>
  props?.data?.series?.some((item) => item.total > 0)
)

const series = computed(() => {
  if (!hasData.value) {
    return []
  }
  return (props?.data?.series ?? []).map(({ data, period }) => ({
    id: period,
    data,
    type: 'bar',
    symbolSize: 10,
    areaStyle: {},
    itemStyle: { borderRadius: 2 },
    barMaxWidth: 16,
    barMinHeight: 6,
    barGap: 0
  }))
})

const chartData = computed(() => ({
  animation: false,
  title: {
    show: !hasData.value,
    textStyle: {
      color: '#a7abb1',
      fontSize: '0.875rem',
      fontWeight: 'normal'
    },
    text: t('pages.home.widgets.no_data_available'),
    left: 'center',
    top: 'center'
  },
  xAxis: {
    show: hasData.value,
    type: 'value',
    nameTextStyle: {
      color: 'red'
    },
    axisLabel: {
      formatter: (value) => {
        const params = getNumberAbbreviationParams(value)
        return t(params.key, { value: params.value })
      }
    },
    splitNumber: 3
  },
  yAxis: {
    show: hasData.value,
    type: 'category',
    data: props?.data?.axisLabels ?? [],
    axisLine: { show: false },
    axisTick: { show: false },
    splitLine: { show: false },
    inverse: true
  },
  grid: {
    left: 0,
    bottom: 0,
    top: 0,
    right: 20,
    containLabel: true
  },
  tooltip: {
    transitionDuration: 0,
    formatter: ({ name, color, data, seriesId, seriesIndex, dataIndex }) => {
      const currency = props?.data?.series[seriesIndex].unit
      const unitsSold =
        props?.data?.extraData.productUnitsSold[seriesId][dataIndex] ?? 0
      return `

      <div class="flex flex-col">
        <div class="text-sm font-semibold">
          <span class="inline-block mr-2 w-2 h-2 rounded-full" style="background-color: ${color};"></span>
          ${name}
        </div>
        <div class="text-sm font-semibold">
          ${t('pages.home.revenue')}: ${formatCurrency(data, currency)}
        </div>
        <div class="text-sm">
          ${t('pages.home.units_sold')}: ${unitsSold}
        </div>
        <div class="text-sm">
          ${t('pages.home.period.label')}: ${periodTranslations.value[seriesId]}
        </div>
      </div>`
    }
  },
  color: ['#279ff6', '#A9D9FB'],
  series: series.value
}))
</script>
