<template>
  <div
    class="sortable-header"
    :class="{
      ascending: modelValue === ORDER.ASC,
      descending: modelValue === ORDER.DESC
    }"
    @click="changeSort()"
  >
    <slot />
    <span class="caret-wrapper">
      <i class="sort-caret ascending" @click.stop="changeSort(ORDER.ASC)" />
      <i class="sort-caret descending" @click.stop="changeSort(ORDER.DESC)" />
    </span>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, reactive } from 'vue'

const ORDER = reactive({
  ASC: 'asc',
  DESC: 'desc'
})

const props = defineProps({
  modelValue: {
    type: String,
    validator: (val) => ['asc', 'desc'].includes(val) || val === null,
    default: null
  }
})

const emit = defineEmits(['update:modelValue'])

function changeSort(value) {
  let sort = value
  if (!sort) {
    if (props.modelValue === null || props.modelValue === ORDER.DESC) {
      sort = ORDER.ASC
    } else {
      sort = ORDER.DESC
    }
  } else {
    if (
      (props.modelValue === ORDER.DESC && value === ORDER.DESC) ||
      (props.modelValue === ORDER.ASC && value === ORDER.ASC)
    ) {
      sort = null
    }
  }
  emit('update:modelValue', sort)
}
</script>

<style scoped>
.sortable-header {
  cursor: pointer;
}
.caret-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  height: 14px;
  width: 24px;
  vertical-align: middle;
  cursor: pointer;
  overflow: initial;
  position: relative;

  height: 17px;
  width: 10px;
  margin-left: 0.3rem;
}

.sort-caret {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  position: absolute;
}

.sort-caret.ascending {
  border-bottom-color: var(--el-text-color-placeholder);
  top: -4px;
  left: 0;
}

.sort-caret.descending {
  border-top-color: var(--el-text-color-placeholder);
  bottom: -1px;
  left: 0;
}

.ascending .sort-caret.ascending {
  border-bottom-color: var(--el-color-primary);
}

.descending .sort-caret.descending {
  border-top-color: var(--el-color-primary);
}
</style>
