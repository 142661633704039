<template>
  <div
    class="text-center flex flex-col h-full items-center justify-center"
    :class="{ 'cursor-pointer': redirect }"
    @click="handleClick"
  >
    <p class="font-bold text-4xl xs:text-2xl sm:text-3xl md:text-3xl mt-2 mb-4">
      {{ value }}
    </p>
    <WidgetDiff class="mb-4 text-sm" :diff="diff" />
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
import WidgetDiff from './widget-diff.vue'

const props = defineProps({
  diff: {
    type: String,
    required: true
  },
  unit: {
    type: String,
    default: ''
  },
  value: {
    type: String,
    required: true
  },
  redirect: {
    type: Object,
    default: null
  }
})

const router = useRouter()

function handleClick() {
  if (props.redirect) {
    router.push(props.redirect)
  }
}
</script>
